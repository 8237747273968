article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

a {
  background: transparent; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

hr {
  box-sizing: content-box;
  height: 0; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  margin: 0 2px; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-spacing: 0; }

/*# sourceMappingURL=normalise.css.map */
